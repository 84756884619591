export const US_STATES = [
  { name:'Alabama', key:'AL', short: 'AL' },
  { name:'Alaska', key:'AK', short: 'AK'},
  { name:'Arizona', key:'AZ', short: 'AZ'},
  { name:'Arkansas', key:'AR', short: 'AR'},
  { name:'California', key:'CA', short: 'CA'},
  { name:'Colorado', key:'CO', short: 'CO'},
  { name:'Connecticut', key:'CT', short: 'CT'},
  { name:'Delaware', key:'DE', short: 'DE'},
  { name:'District of Columbia', key:'DC', short: 'DC'},
  { name:'Florida', key:'FL', short: 'FL'},
  { name:'Georgia', key:'GA', short: 'GA'},
  { name:'Hawaii', key:'HI', short: 'HI'},
  { name:'Idaho', key:'ID', short: 'ID'},
  { name:'Illinois', key:'IL', short: 'IL'},
  { name:'Indiana', key:'IN', short: 'IN'},
  { name:'Iowa', key:'IA', short: 'IA'},
  { name:'Kansas', key:'KS', short: 'KS'},
  { name:'Kentucky', key:'KY', short: 'KY'},
  { name:'Louisiana', key:'LA', short: 'LA'},
  { name:'Maine', key:'ME', short: 'ME'},
  { name:'Maryland', key:'MD', short: 'MD'},
  { name:'Massachusetts', key:'MA', short: 'MA'},
  { name:'Michigan', key:'MI', short: 'MI'},
  { name:'Minnesota', key:'MN', short: 'MN'},
  { name:'Mississippi', key:'MS', short: 'MS'},
  { name:'Missouri', key:'MO', short: 'MO'},
  { name:'Montana', key:'MT', short: 'MT'},
  { name:'Nebraska', key:'NE', short: 'NE'},
  { name:'Nevada', key:'NV', short: 'NV'},
  { name:'New Hampshire', key:'NH', short: 'NH'},
  { name:'New Jersey', key:'NJ', short: 'NJ'},
  { name:'New Mexico', key:'NM', short: 'NM'},
  { name:'New York', key:'NY', short: 'NY'},
  { name:'North Carolina', key:'NC', short: 'NC'},
  { name:'North Dakota', key:'ND', short: 'ND'},
  { name:'Ohio', key:'OH', short: 'OH'},
  { name:'Oklahoma', key:'OK', short: 'OK'},
  { name:'Oregan', key:'OR', short: 'OR'},
  { name:'Pennsilvania', key:'PA', short: 'PA'},
  { name:'Rhode Island', key:'RI', short: 'RI'},
  { name:'South Carolina', key:'SC', short: 'SC'},
  { name:'South Dakota', key:'SD', short: 'SD'},
  { name:'Tennessee', key:'TN', short: 'TN'},
  { name:'Texas', key:'TX', short: 'TX'},
  { name:'Utah', key:'UT', short: 'UT'},
  { name:'Vermont', key:'VT', short: 'VT'},
  { name:'Virginia', key:'VA', short: 'VA'},
  { name:'Washington', key:'WA', short: 'WA'},
  { name:'West Virginia', key:'WV', short: 'WV'},
  { name:'Wisconsin', key:'WI', short: 'WI'},
  { name:'Wyoming', key:'WY', short: 'WY'}
]