'use client'
import ApiUtils from "@/utils/ApiUtils";

export default function WidgetWrapper(props) {

  ApiUtils.setPartnerKey(props.config.partner_key)

  return <>
    { props.children }
  </>

}