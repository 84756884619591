'use client'

import { useEffect, useState } from 'react'
import Tabs from '@/components/tabs/Tabs'
import styles from './hx.module.scss'
import { LOGO } from '@/app/logo'
import { useRouter } from 'next/navigation'

import ApiUtils from '@/utils/ApiUtils'
import { US_STATES } from '@/components/form/Constants'
import WidgetLogo from './WidgetLogo'
import Footer from '../Footer'
import WidgetLogoFooter from './WidgetLogoFooter'
import { OPTIONS_CONDITIONS, OPTIONS_DAMAGES } from '@/utils/Constants'

const conditionOptions = OPTIONS_CONDITIONS
const damageOptions = OPTIONS_DAMAGES

export default function WidgetStart(props) {

  const router = useRouter()

  const tabs = [
    { key: 'plate', labels: [{ label: 'Plate', className: '' }] },
    { key: 'vin', labels: [{ label: 'VIN', className: '' }] }
  ]

  const [selectedTab,setSelectedTab] = useState("plate")
  const [form,setForm] = useState({})
  const [processing,setProcessing] = useState(false)
  const [error,setError] = useState()

  useEffect(() => {
    ApiUtils.setVisitorId(props.vid)
  }, [])

  const onTypeChange = (tab) => {
    setSelectedTab(tab.key)
  }

  const onFieldChange = (e, field) => {
    let data = Object.assign({}, form)
    data[field] = e.target.value
    setForm(data)
  }

  const isValidZip = (zipCode) => {
    const regex = /^\d{5}$/;
    return regex.test(zipCode)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)
    if (!((selectedTab == 'vin' && form.vin) || (selectedTab == 'plate' && form.plate && form.plateState)) || !form.mileage || !form.zip || !isValidZip(form.zip)) {
      setProcessing(false)
      setError("Please enter correct information in all the fields above.")
      return
    }
    if (!form.condition) {
      setProcessing(false)
      setError("Please select a Condition rating.")
      return
    }
    if (!form.damage) {
      setProcessing(false)
      setError("Please select a Damage rating.")
      return
    }
    let params = {
      type: selectedTab,
      zip: form.zip,
      mileage: form.mileage
    }
    if (selectedTab == 'plate') {
      params.plate = form.plate
      params.state = form.plateState
    }
    if (selectedTab == 'vin') {
      params.vin = form.vin
    }
    params.condition = form.condition
    params.damage = form.damage
    ApiUtils.getRequest("/platform/v1/vehicles/decode?", params)
    .then(data => {
      if (data.error) {
        setProcessing(false)
        setError("We could not find your vehicle. Please check and try again.")
      } else {
        const payload = Object.assign(data, {
          key: props.config.partner_key,
          vid: ApiUtils.visitorId(),
          zip: form.zip,
          mileage: form.mileage
        })
        const encoded = btoa(JSON.stringify(data))
        router.push("/widget/offers?hash=" + encoded)
      }
    })
  }

  const startFromScratch = () => {
    ApiUtils.clearToken()
    setTimeout(() => {
      ApiUtils.setVisitorId()
    }, 1000)
  }

  return <div className={styles.main + " hr-body"}>
    <div className='column'>
    <form className={'form'} id="hx_vehicle_id_form" name="Vehicle Identifier Form" onSubmit={e => onSubmit(e)}>
      <WidgetLogo {...props}></WidgetLogo>
      <h1 className={styles.title + ' hr-vehicle-title'}>{ props.config.strings?.startTitle || 'Sell Briliantly.' }</h1>
      <h3 className={styles.subtitle + ' hr-vehicle-subtitle'}>{ props.config.strings?.startSubTitle || 'Realtime offers from thousands of qualified buyers in seconds.' }</h3>
      <Tabs items={tabs} selected={selectedTab} onChange={tab => onTypeChange(tab)}></Tabs>
      <div className='form-padded hr-form-container'>
        <div className='form-cells hr-form-cells'>
          { selectedTab == 'plate' &&
          <div className='form-cell hr-form-cell form-cell50'>
            <input className="hr-input-text" type="text" name="plate" value={form.plate || ''} aria-label="Plate" placeholder="Plate Number" onChange={e => onFieldChange(e, 'plate')}></input>
          </div>
          }
          { selectedTab == 'plate' &&
          <div className='form-cell hr-form-cell form-cell50'>
            <select className="hr-input-select" name="state" aria-label="State" placeholder="State" value={ form.plateState || '' } onChange={e => onFieldChange(e, 'plateState')}>
              <option value="" disabled>State</option>
              { US_STATES.map((item,i) => (
                <option key={item.key} value={item.key}>{ item.name }</option>
              ))}
            </select>
          </div>
          }
          { selectedTab == 'vin' &&
          <div className='form-cell hr-form-cell'>
            <input className="hr-input-text" type="text" name="vin" value={form.vin || ''} aria-label="VIN Number" placeholder="VIN Number" onChange={e => onFieldChange(e, 'vin')}></input>
          </div>
          }
          <div className='form-cell hr-form-cell form-cell50'>
            <select className="hr-input-select" name="condition" aria-label="Condition" placeholder="Condition" value={ form.condition } onChange={e => onFieldChange(e, 'condition')}>
              <option value="">Conditon</option>
              { conditionOptions.map((item,i) => (
                <option key={item} value={item}>{ item }</option>
              ))}
            </select>
          </div>
          <div className='form-cell hr-form-cell form-cell50'>
            <select className="hr-input-select" name="damage" aria-label="Damage" placeholder="Damage" value={ form.damage } onChange={e => onFieldChange(e, 'damage')}>
              <option value="">Damage</option>
              { damageOptions.map((item,i) => (
                <option key={item} value={item}>{ item }</option>
              ))}
            </select>
          </div>
          <div className='form-cell hr-form-cell form-cell50'>
            <input className="hr-input-text" type="text" name="mileage" value={form.mileage || ''} aria-label="Miles" placeholder="Miles (Odometer)" onChange={e => onFieldChange(e, 'mileage')}></input>
          </div>
          <div className='form-cell hr-form-cell form-cell50'>
            <input className="hr-input-text" type="text" name="zip" value={form.zip || ''} aria-label="Zip Code" placeholder="Your Zip Code" onChange={e => onFieldChange(e, 'zip')}></input>
          </div>
          { error &&
          <div className='form-cell hr-form-cell text-align-center text-red text-bold'>
            { error }
          </div>
          }
          <div className='form-cell hr-form-cell'>
            <button type="submit" className={'button-base hr-button button-primary hr-button-primary hr-vehicle-submit ' + (processing ? 'button-processing hr-button-processing' : '')}>
              <span>{ props.config.strings?.viewMyOffers || 'View My Offers' }</span>
              { LOGO }
            </button>
          </div>
        </div>
        <div className='disclaimer' dangerouslySetInnerHTML={{__html: props.config.disclaimers.viewMyOffers}}></div>
      </div>
      <div className={styles.footer}>
        <div>Powered by</div>
        <WidgetLogoFooter {...props}></WidgetLogoFooter>
      </div>
      <Footer {...props}></Footer>
    </form>
    </div>
  </div>

}