'use client'

import { useState } from 'react'
import styles from './hx.module.scss'

export default function Tabs(props) {
  
  const theme = "simple"
  const [selected, setSelected] = useState(props.selected)
  
  const onChange = (e, item) => {
    e.preventDefault()
    setSelected(item.key || item.type || item.value)
    props.onChange(item)
  }
  
  const renderLabels = (item) => {
    if (item.labels) {
      return <>
        { item.labels.map((e,i) => (
          <label key={i} className={e.className}>{e.label}</label>
        ))}
      </>
    } else {
      return item.label
    }
  }
  
  const ariaLabels = (item) => {
    if (item.labels) {
      return item.labels.map((e,i) => {
        return e.label
      }).join(", ")
    } else {
      return item.label
    }
  }
  
  return <div className={ styles[theme + "-container"] + " hr-tabs-container" }>
    { props.items.map((item, index) => (
      <button key={index} className={ styles[theme + "-item"] + " hr-tabs-item " + (selected == (item.key || item.type || item.value) ? "hr-tabs-item-selected" : '') } onClick={e => onChange(e, item)} label={ ariaLabels(item) }>
        { renderLabels(item) }
        <div className={ styles[theme + "-bar"] + ' hr-tabs-item-bar ' + (selected == (item.key || item.type || item.value) ? styles[theme + "-selected"] + ' hr-tabs-item-bar-selected' : '') }></div>
      </button>
    ))}
  </div>
  
}