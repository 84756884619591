import styles from './hx.module.scss'

export default function WidgetLogo(props) {

  if (props.config.no_logo) return <></>

  return <section className={styles.main_logo}>
    <img src={ props.config.logo || '/logo-with-name.png'}></img>
  </section>


}