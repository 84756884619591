export const LOGO = (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 89.62 98.12">
	<path fill="currentColor" d="M80.78-126.92l-10.47-5.93v9.98l6.11,3.46c2.26,1.28,3.67,3.55,3.67,5.92V-79c0,2-1.03,3.8-2.76,4.83
	L47.74-56.59c-2.08,1.24-4.8,1.2-7.09-0.1l-3.47-1.96v-25.88l0.86-1.28l14.17-7.83l0.86,0.79v18.22l0.86,0.79l10.31-5.86l0.86-0.81
	v-35.84v-9.45v-9.98l-11.77-6.67c-5.53-3.13-12.08-3.23-17.1-0.25L6.66-125.13C2.49-122.65,0-118.3,0-113.48v34.5
	c0,5.71,3.39,11.17,8.84,14.26l11.11,6.29v-9.98l-6.75-3.82c-2.26-1.28-3.67-3.55-3.67-5.92v-34.5c0-2,1.03-3.8,2.76-4.83
	l29.58-17.58c1.19-0.71,2.59-1,3.99-0.87c1.06,0.09,2.12,0.42,3.1,0.98l4.1,2.32v25.45l-0.86,1.35l-14.17,7.58l-0.86-0.79v-17.38
	l-0.86-0.79l-10.31,6.37l-0.86,0.81v33.62v10.93v9.98l11.13,6.31c2.37,1.34,4.93,2.13,7.47,2.35c3.39,0.3,6.76-0.4,9.63-2.1
	l29.58-17.58c4.17-2.48,6.66-6.83,6.66-11.65v-34.5C89.62-118.37,86.23-123.83,80.78-126.92z"/>
	<path fill="currentColor" d="M80.78,17.96l-10.47-5.93v9.98l6.11,3.46c2.26,1.28,3.67,3.55,3.67,5.92v34.5c0,2-1.03,3.8-2.76,4.83
	L47.74,88.29c-2.08,1.24-4.8,1.2-7.09-0.1l-3.47-1.96V60.35l0.86-1.28l14.17-7.83l0.86,0.79v18.22l0.86,0.79l10.31-5.86l0.86-0.81
	V28.52v-9.45V9.08L53.34,2.42c-5.53-3.13-12.08-3.23-17.1-0.25L6.66,19.75C2.49,22.23,0,26.58,0,31.4v34.5
	c0,5.71,3.39,11.17,8.84,14.26l11.11,6.29v-9.98l-6.75-3.82c-2.26-1.28-3.67-3.55-3.67-5.92v-34.5c0-2,1.03-3.8,2.76-4.83
	L41.88,9.82c1.19-0.71,2.59-1,3.99-0.87c1.06,0.09,2.12,0.42,3.1,0.98l4.1,2.32V37.7l-0.86,1.35l-14.17,7.58l-0.86-0.79V28.46
	l-0.86-0.79l-10.31,6.37l-0.86,0.81v33.62v10.93v9.98l11.13,6.31c2.37,1.34,4.93,2.13,7.47,2.35c3.39,0.3,6.76-0.4,9.63-2.1
	l29.58-17.58c4.17-2.48,6.66-6.83,6.66-11.65v-34.5C89.62,26.51,86.23,21.05,80.78,17.96z"/>
	<g>
		<path fill="currentColor" d="M129.75-106.63h5.37v13.49h16.19v-13.49h5.41v32.38h-5.41v-13.49h-16.19v13.49h-5.37V-106.63z"/>
		<path fill="currentColor" d="M170.93-106.63h5.37v32.38h-5.37V-106.63z"/>
		<path fill="currentColor" d="M203.59-106.63c2.24,0,4.34,0.42,6.3,1.26c1.96,0.84,3.69,2.01,5.18,3.5l-3.87,3.83
		c-2.08-2.12-4.62-3.17-7.61-3.17c-2.96,0-5.51,1.06-7.65,3.17c-2.08,2.12-3.13,4.65-3.13,7.61c0,2.95,1.04,5.51,3.13,7.65
		c2.15,2.08,4.7,3.13,7.65,3.13c1.62,0,3.12-0.32,4.5-0.96c1.38-0.64,2.59-1.53,3.62-2.68v-4.43h-8.12v-5.37h13.53v11.57
		c-0.75,1.09-1.61,2.08-2.59,2.99c-0.98,0.9-2.05,1.67-3.2,2.31c-1.15,0.64-2.38,1.13-3.69,1.47c-1.31,0.34-2.66,0.51-4.06,0.51
		c-4.48,0-8.29-1.59-11.43-4.76c-3.17-3.14-4.76-6.95-4.76-11.43c0-4.45,1.59-8.26,4.76-11.43
		C195.3-105.04,199.11-106.63,203.59-106.63z"/>
		<path fill="currentColor" d="M229.11-106.63h5.37v13.49h16.19v-13.49h5.41v32.38h-5.41v-13.49h-16.19v13.49h-5.37V-106.63z"/>
		<path fill="currentColor" d="M289.28-74.24l-6.25-10.78h-9.15v10.78h-5.37v-32.38h16.19c2.95,0,5.49,1.06,7.61,3.17
		c2.12,2.12,3.17,4.65,3.17,7.61c0,2.24-0.64,4.28-1.91,6.11c-1.24,1.8-2.83,3.1-4.76,3.87l6.67,11.62H289.28z M284.71-101.21
		h-10.83v10.73l10.83,0.05c1.46,0,2.72-0.53,3.78-1.59c1.06-1.06,1.59-2.33,1.59-3.83c0-1.46-0.53-2.72-1.59-3.78
		C287.43-100.68,286.17-101.21,284.71-101.21z"/>
		<path fill="currentColor" d="M321.01-106.63c4.48,0,8.29,1.59,11.43,4.76c3.2,3.17,4.81,6.98,4.81,11.43c0,4.51-1.6,8.32-4.81,11.43
		c-3.14,3.17-6.95,4.76-11.43,4.76c-4.48,0-8.29-1.59-11.43-4.76c-3.17-3.14-4.76-6.95-4.76-11.43c0-4.45,1.59-8.26,4.76-11.43
		C312.69-105.04,316.5-106.63,321.01-106.63z M321.01-101.21c-2.96,0-5.51,1.06-7.65,3.17c-2.08,2.12-3.13,4.65-3.13,7.61
		c0,2.95,1.04,5.51,3.13,7.65c2.15,2.08,4.7,3.13,7.65,3.13c2.95,0,5.49-1.04,7.61-3.13c2.12-2.15,3.17-4.7,3.17-7.65
		c0-2.96-1.06-5.49-3.17-7.61C326.5-100.15,323.97-101.21,321.01-101.21z"/>
		<path fill="currentColor" d="M364.88-79.65h-14.89l-2.75,5.41h-6.02l16.19-32.38l16.19,32.38h-6.02L364.88-79.65z M352.65-85.02h9.52
		l-4.76-9.52L352.65-85.02z"/>
		<path fill="currentColor" d="M382.94-106.63h10.78c2.21,0,4.29,0.43,6.25,1.28c1.96,0.86,3.68,2.02,5.16,3.5c1.48,1.48,2.64,3.2,3.5,5.16
		c0.86,1.96,1.28,4.04,1.28,6.25c0,2.21-0.43,4.29-1.28,6.25c-0.86,1.96-2.01,3.68-3.48,5.16c-1.46,1.48-3.18,2.64-5.16,3.5
		c-1.98,0.86-4.07,1.28-6.28,1.28h-10.78V-106.63z M393.71-79.65c1.49,0,2.89-0.28,4.2-0.84c1.31-0.56,2.45-1.34,3.43-2.33
		c0.98-0.99,1.75-2.15,2.31-3.45c0.56-1.31,0.84-2.69,0.84-4.15c0-1.46-0.28-2.85-0.84-4.15c-0.56-1.31-1.34-2.46-2.33-3.45
		c-0.99-0.99-2.15-1.77-3.45-2.33c-1.31-0.56-2.69-0.84-4.15-0.84h-5.41v21.56H393.71z"/>
	</g>
</svg>)

